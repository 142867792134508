@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
nav {
	width: 100%;
	position: sticky;
	z-index: 10;
	top: 0;
	padding: 1.2em 0;
	color: white;
	background-color: var(--darkAccent);
	max-height: 4em;
}

nav span {
	font-size: 18px;
	border-bottom: 1px solid transparent;
	transition: border-bottom 0.2s ease;
	transition-delay: 0.25s;
	margin: 1em;
	padding-bottom: 0.3em;
}

nav span:hover {
	cursor: pointer;
	border-bottom: 1px solid white;
}

nav span:hover i {
	color: gold;
}

nav span.activeClass i {
	color: gold;
}

nav span.activeClass {
	border-bottom-color: var(--lightAccent);
}
#nameTag{
	pointer-events: none;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 0;
	font-family: 'Secular One', sans-serif;
	font-size:20px
}