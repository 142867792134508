section {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
	border-bottom: 1px solid lightgrey;
}
#Iam{
	font-family: 'Secular One', sans-serif;
	font-size: 22px;

}
section:nth-child(even) {
	flex-direction: row-reverse;
}

section img,
section div {
	height: auto;
	width: 100%;
}

section img {
	width: 50%;
}

section div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 1em;
}

.typewriter p {
	border-right: .15em solid orange;
	font-family: "Courier";
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
}

.typewriter p:nth-child(1) {
	width: 27em;
	-webkit-animation: type 2s steps(40, end);
	animation: type 2s steps(30, end);
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.typewriter p:nth-child(2) {
	width: 18em;
	opacity: 0;
	-webkit-animation: type2 2s steps(40, end);
	animation: type2 2s steps(40, end);
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}
.typewriter p:nth-child(3) {
	width: 12em;
	opacity: 0;
	-webkit-animation: type2 2s steps(40, end);
	animation: type2 2s steps(40, end);
	-webkit-animation-delay: 4s;
	animation-delay: 4s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}
.typewriter p:nth-child(4) {
	width: 16em;
	opacity: 0;
	-webkit-animation: type2 2s steps(40, end);
	animation: type2 2s steps(40, end);
	-webkit-animation-delay: 6s;
	animation-delay: 6s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}
.typewriter p:nth-child(5) {
	width: 5em;
	opacity: 0;
	-webkit-animation: type2 2s steps(80, end);
	animation: type2 2s steps(80, end);
	-webkit-animation-delay: 8s;
	animation-delay: 8s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}
@keyframes type {
	0% {
		width: 0;
	}

	99.9% {
		border-right: .15em solid orange;
	}

	100% {
		border: none;
	}
}

@-webkit-keyframes type {
	0% {
		width: 0;
	}

	99.9% {
		border-right: .15em solid orange;
	}

	100% {
		border: none;
	}
}

@keyframes type2 {
	0% {
		width: 0;
	}

	1% {
		opacity: 1;
	}

	99.9% {
		border-right: .15em solid orange;
	}

	100% {
		opacity: 1;
		border: none;
	}
}

@-webkit-keyframes type2 {
	0% {
		width: 0;
	}

	1% {
		opacity: 1;
	}

	99.9% {
		border-right: .15em solid orange;
	}

	100% {
		opacity: 1;
		border: none;
	}
}

@keyframes type3 {
	0% {
		width: 0;
	}

	1% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes type3 {
	0% {
		width: 0;
	}

	1% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes blink {
	50% {
		border-color: transparent;
	}
}

@-webkit-keyframes blink {
	50% {
		border-color: tranparent;
	}
}
@media screen and (max-width: 900px) {

	section,
	section:nth-child(even) {
		flex-direction: column;
	}

	section img {
		height: auto;
		width: auto;
		max-width: 100vw;
	}
}