@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap');

:root {
  --darkAccent: rgba(2, 0, 36, 1);
  --lightAccent: #f3d6e2;
}

* {
  box-sizing: border-box;
}

*,
html {
  line-height: 1em;
  padding: 0;
  margin: 0;
  font-family: 'Libre Franklin', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3 {
  font-size: 2.6em;
  text-align: center;
  padding: 1em;
  font-weight: 500;
}
ul {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
  text-indent: -1em;
  font-size: 20px;
    text-align: center;

}
li:before {
  content: "►";
  display: block;
  float: left;
  width: 1.2em;
  color: var(--darkAccent);
  margin-right: 22px;
}
li{
  margin-bottom: 22px;
}
.icon{
  width:40px;
  height:40px;
}
.mailIcon{
  width: 55px;
  height: 50px;
}
.linkedInIcon{
  width: 70px;
  height: 70px;
}